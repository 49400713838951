// Copyright © 2022 Move Closer

import { AnyObject } from '@movecloser/front-core'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_CAROUSEL_DEFAULT_CONFIG = {
  draggable: true,
  hasPagination: false,
  inLoop: false,
  pauseOnHover: false,
  spaceBetween: 8,
  swipeToSlide: true,
  variableWidth: true
}

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_KEY = 'VariantsSwitch'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export type CarouselConfig = Record<string, string | number | boolean | AnyObject>

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface VariantsSwitchConfig {
  carouselConfig: CarouselConfig
}

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_CONFIG_MAP: VariantsSwitchConfig = {
  carouselConfig: VARIANTS_SWITCH_CAROUSEL_DEFAULT_CONFIG
}
