





























/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ref } from '@vue/composition-api'
import { AnyObject } from '@movecloser/front-core'

import { AllowedImageRatio, ImageData } from '../../../../../contexts'

import { toImageProps } from '../../../../shared/support'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'

@Component({ name: 'Default' })
export class Default extends Vue {
  @PropSync('model', {
    required: false,
    default: null
  })
  public _model!: VariantsSwitchProps['model']

  @Prop({
    type: Array,
    required: false
  })
  public variants?: Variant[]

  public hasButtons = ref<boolean>(true)

  @Prop({ type: Object, required: true })
  public readonly carouselConfig!: AnyObject

  public getStyle (identifier: string | ImageData): AnyObject {
    if (typeof identifier === 'string') {
      return { backgroundColor: identifier }
    }

    return { backgroundImage: `url(${toImageProps(identifier, AllowedImageRatio.Square, 50).src})` }
  }

  public updateNavigationVisibility = () => {
    if (this.$refs.carousel) {
      const carusel = this.$refs.carousel as HTMLDivElement
      const carouselWidth = carusel.offsetWidth
      const totalSlidesWidth = Array.from(carusel.querySelectorAll('.slick-slide')).reduce(
        (acc, slide) => acc + (slide as HTMLElement).offsetWidth,
        0
      )

      if (totalSlidesWidth <= carouselWidth) {
        this.hasButtons.value = false
      } else {
        this.hasButtons.value = true
      }
    }
  }

  created () {
    window.addEventListener('resize', this.updateNavigationVisibility)
  }

  destroyed () {
    window.removeEventListener('resize', this.updateNavigationVisibility)
  }
}
export default Default
