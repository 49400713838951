








/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import {
  Variant,
  VariantsSwitchProps,
  VariantsSwitchType,
  variantsSwitchTypeRegistry
} from './VariantsSwitch.contracts'
import {
  VARIANTS_SWITCH_CONFIG_MAP,
  VARIANTS_SWITCH_KEY,
  VariantsSwitchConfig,
  CarouselConfig
} from './VariantsSwitch.config'
import { CartModel } from '../../../checkout/contracts/models'
import { BenefitProgram } from '../../../loyalty/contracts/programs'
import { StructureConfigurable } from '../../../../support/mixins'

@Component<VariantsSwitch>({
  name: 'VariantsSwitch',
  async created () {
    this.config = this.getComponentConfig(VARIANTS_SWITCH_KEY, { ...VARIANTS_SWITCH_CONFIG_MAP })
  }
})
export class VariantsSwitch extends Mixins(StructureConfigurable) {
  protected config!: VariantsSwitchConfig

  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ required: false, default: null })
  public readonly cart!: CartModel | null

  @Prop({ required: false })
  public readonly loyaltyPayload?: Record<BenefitProgram, Record<string, unknown>>

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFaF!: boolean

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  @Prop({ type: String, required: false, default: VariantsSwitchType.Default })
  public type?: string

  @Prop({ type: Array, required: false, default: null })
  public readonly bundleSkus!: Array<string> | null

  public get carouselConfig (): CarouselConfig {
    return this.getConfigProperty('carouselConfig')
  }

  /**
   * Get component by type.
   */
  public get component (): VueConstructor {
    if (typeof this.type === 'undefined') {
      return variantsSwitchTypeRegistry[VariantsSwitchType.Default]
    }

    return variantsSwitchTypeRegistry[this.type]
  }
}

export default VariantsSwitch
