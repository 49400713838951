






















import { AnyObject } from '@movecloser/front-core'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { ImageProps } from '../../../../../../dsl/atoms/Image'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../../support'

import { Direction } from './Thumbnails.contrats'
import { Carousel } from '../../../../../../dsl/molecules/Carousel'
import { defaultCarouselConfig } from './Thumbnails.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<Thumbnails>({ name: 'Thumbnails' })
export class Thumbnails extends Vue {
  /**
   * Determines whether the app is running on a mobile device.
   */
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: false, type: Object, default: defaultCarouselConfig })
  public readonly carouselConfiguration!: AnyObject

  @Prop({ required: false, type: String, default: () => Direction.Horizontal })
  public direction!: Direction

  @Prop({ required: true, type: Array })
  public thumbnails!: ImageProps[]

  @Prop({ required: true, type: Number })
  public selectedImageIndex!: number

  public height: string | null = null
  protected carousel: Carousel | null = null

  /**
   * Handles @onInit of the carousel component.
   */
  public onCarousel (carouselInstance: Carousel): void {
    if (!carouselInstance) {
      return
    }

    this.carousel = carouselInstance
    const innerSlider = this.carousel?.$refs?.innerSlider as unknown as {slideHeight: number}
    this.height = this.thumbnails.length * innerSlider.slideHeight + 'px'
  }

  public get isVertical (): boolean {
    return this.direction === Direction.Vertical
  }

  /**
   * Handles  changing slide to the next one
   */
  public slideNext (index: number) {
    if (index < this.thumbnails.length - 1) {
      this.carousel?.next()
      this.changeSelectedImage(index + 1)
    }
  }

  /**
   * Handles changing slide to the previous one
   */
  public slidePrev (index: number) {
    if (index > 0) {
      this.carousel?.prev()
      this.changeSelectedImage(index - 1)
    }
  }

  /**
   * Emits the on slide change event.
   */
  protected changeSelectedImage (index: number) {
    this.$emit('change', index)
  }
}

export default Thumbnails
