
import { Component, Vue } from 'vue-property-decorator'
import { Inject, logger } from '../../../support'
import { ITrustedShop, TrustedShopRepositoryType, TrustedShopReview } from '../repositories/trustedShop'
import { ProductReviewData } from '../../../contexts'
import { RateSummaryProps } from '../../products/molecules/RateSummary/RateSummary.contracts'
import { ProductReviewPropsSimple } from '../../products/molecules/ProductReview'

/**
 * Mixin capable of retrieving TrustedShop ratings
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<TrustedShopReviewsMixin>({
  name: 'ProductRatingMixin',
  destroyed () {
    this.unstoreProduct()
  }
})
export class TrustedShopReviewsMixin extends Vue {
  @Inject(TrustedShopRepositoryType)
  protected readonly trustedShopRepository?: ITrustedShop

  public productReviews: Array<ProductReviewPropsSimple> | null = null
  public rateSummary: RateSummaryProps | null = null
  public totalReviewsCount: number = 0
  public averageRating: number = 0

  /**
   * Retrieves a total number of reviews for a specific sku
   * @protected
   */
  protected async loadProductReviews (skus: Array<string>): Promise<void> {
    if (!this.trustedShopRepository || skus.length === 0) {
      return
    }

    try {
      const aggregateReviews = []
      for (const sku of skus) {
        aggregateReviews.push(await this.trustedShopRepository.getReviewsBySku(sku))
      }

      const reviewItems = []
      let counter = 1

      for (const element of Object.values(aggregateReviews)) {
        for (const item of element.items) {
          reviewItems.push(item)
          counter++
        }
      }

      this.rateSummary = {
        ...TrustedShopReviewsMixin.composeRateSummary(reviewItems)
      }
      this.productReviews = TrustedShopReviewsMixin.composeReviews(reviewItems).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.$store.commit('products/setProductReviewsData', this.productReviews)
      this.$store.commit('products/setRateSummary', this.rateSummary)
      this.calculateAverageRating(this.productReviews && this.productReviews.length > 0 ? this.productReviews : null)
    }
  }

  /**
   * Calculates and stores average rating for single product (including it's variants)
   * @protected
   */
  protected calculateAverageRating (reviews: Array<ProductReviewPropsSimple> | null) {
    if (!reviews || reviews.length === 0) {
      this.$store.commit('products/setAverageProductRating', 0)
      return
    }

    const count = reviews.length
    const averageRating = reviews.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.rate
    }, 0)

    this.averageRating = averageRating / count

    this.$store.commit('products/setAverageProductRating', this.averageRating)
  }

  /**
   * Retrieves a total number of reviews for a specific sku
   * @protected
   */
  protected async loadReviewsTotalCount (skus: Array<string>): Promise<void> {
    if (!this.trustedShopRepository || skus.length === 0) {
      return
    }

    try {
      for (const sku of skus) {
        const total = await this.trustedShopRepository.getReviewsCountForSku(sku.toString())
        this.totalReviewsCount += total.totalElements
      }
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.$store.commit('products/setTotalReviewsCount', this.totalReviewsCount)
    }
  }

  protected unstoreProduct () {
    this.$store.commit('products/setAverageProductRating', 0)
    this.$store.commit('products/setProductReviewsData', null)
    this.$store.commit('products/setTotalReviewsCount', 0)
  }

  /**
   * Composes RateSummary component props
   * @param reviews
   * @private
   */
  private static composeRateSummary (reviews: Array<TrustedShopReview>) {
    const amountPerRate = [0, 0, 0, 0, 0]
    let totalAmount = 0
    let sumRate = 0

    for (let i = 0; i < reviews.length; i++) {
      amountPerRate[reviews[i].rating - 1]++
      sumRate += reviews[i].rating
    }

    for (const rate of amountPerRate) {
      totalAmount += rate
    }

    return {
      average: {
        amount: reviews.length,
        rate: sumRate / totalAmount
      },
      amountPerRate
    }
  }

  /**
   * Composes ProductReview component props
   * @param reviews
   * @private
   */
  private static composeReviews (
    reviews: Array<TrustedShopReview>
  ): Array<Omit<ProductReviewData, 'color' | 'desc' | 'isVerified' | 'title' | 'location'>> {
    return reviews.map((review: TrustedShopReview) => {
      return {
        author: review.customer.firstName ?? '',
        content: review.comment,
        createdAt: review.submittedAt,
        rate: review.rating,
        comment: review.reply?.comment,
        commentCreatedAt: review.reply?.createdAt
      }
    })
  }
}
